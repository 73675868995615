import { ExternalLink } from 'components/ExternalLink/ExternalLink';
import { Tooltip } from 'components/Tooltip/Tooltip';
import React from 'react';

import styles from './FiscallySignificantTooltip.module.css';

const FiscallySignificantTooltip = () => {
  return (
    <Tooltip ariaLabel="definition of Fiscally Significant" id="fiscalTooltip">
      <p>
        Fiscally significant means the combined implementation and compliance costs of a proposed
        rule are at least $1,000,000 for businesses, units, and individuals over any two year period
        as determined under{' '}
        <ExternalLink
          additionalClassName={styles.icLink}
          linkText="IC 4-22-2-22.7"
          url="https://iga.in.gov/laws/current/ic/titles/4#4-22-2-22.7"
        />
        .
      </p>
    </Tooltip>
  );
};

export default FiscallySignificantTooltip;
